import { programmingObj } from "@app/models/programming";
import {
  Component,
  OnInit,
  Inject,
  NgZone,
  ViewChild,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { EventsService } from "../../core/events.service";
import { AuthService } from "../../core/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { CustomersService } from "../../customers.service";
import { UserService } from "../../core/user.service";
import { ProgramingService } from "../../services/programing.service";
import { MapsAPILoader } from "@agm/core";
import { ProgrammingSearchMapComponent } from "../programming-search-map/programming-search-map.component";
import { ModalGenericService } from "../../services/modal-generic.service";
import { StorageService } from "../../core/storage.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
  map,
} from "rxjs/operators";
import { UtilsService } from "../../core/helper/utils.helper";
import * as moment from "moment";
import * as Moment from "moment";
import { ProgrammingAddressComponent } from "../programming-address/programming-address.component";
import { Subject, timer } from "rxjs";
import { TimezoneConversionService } from "@app/core/timezone-conversion.service";
import { TiposMarker } from "../../models/tipos_marker.model";
import { ClassificationService } from "@app/core/classification.service";
import { EquipmentService } from "../../core/equipment.service";

@Component({
  selector: "app-programming-new-add",
  templateUrl: "./programming-new-add.component.html",
  styleUrls: ["./programming-new-add.component.scss"],
})
export class ProgrammingNewAddComponent implements OnInit, OnDestroy {
  public events_list: any = [];
  public customersList: any[];
  public user_list: any = [];
  public user_list_original: any = [];
  filterEquipmentByUId = "all";
  currentScheduledEquipmentUid = "";
  formulario = {
    scheduled_type_event: null,
    scheduled_name_event: null,
    scheduled_assigned_to: null,
    scheduled_client_uuid: null,
    scheduled_equipment_uid: null,
    scheduled_periodicity: "notrepeat",
    scheduled_date_programming: null,
    scheduled_hour_since: null,
    scheduled_hour_limit: null,
    scheduled_expiration_date: null,
    scheduled_instructions: null,
    scheduled_clasification: null,
    scheduled_subclasification: null,
    scheduled_zone: null,
    scheduled_estimated_duration: "",
    scheduled_estimated_duration_hours: "0",
    scheduled_estimated_duration_minutes: "30",
  };
  submit = false;

  filtroUsuariosZona = false;

  onDestroy: Subject<any> = new Subject<any>();
  tipoSel: string[] = [];
  userSel: string[] = [];
  customSel: string[] = [];
  equipmentSelected: string[] = [];
  classificationSele: string[] = [];
  subclassificationSele: string[] = [];
  categories_list: any[];
  subcategories_list: any[];
  subcategories_list_selected: any[];
  zone_list_selected: any[] = [];
  field_asigneed_to = true;
  equipments = [];

  zoneUserActive: string[] = [];

  onToggleFitroUsuarios() {
    this.filtroUsuariosZona = !this.filtroUsuariosZona;
    if (!this.filtroUsuariosZona) this.userSel = [];
  }
  async getZoneByUserId() {
    const session = this.storageService.getCurrentSession();
    const uid = session.uid;
    let firebase_tenan = await this.authService.getTenancyParams();
    const querySnapshot = await firebase_tenan
      .collection("zones")
      .where("users", "array-contains", uid)
      .get();
    console.log(`query data ${!querySnapshot.empty}`);
    querySnapshot.forEach((doc) => {
      const { users = [] } = doc.data();
      users.forEach((item) => {
        if (!this.zoneUserActive.includes(item)) {
          console.log(`user ${item}`);
          this.zoneUserActive.push(item);
        }
      });
    });
  }

  get getUserList() {
    return (
      this.user_list &&
      this.user_list
        .filter((f) => {
          return this.utilsService.search(f, "displayName", "");
        })
        .filter((f) => {
          return f.disabled !== true;
        })
        .sort(function (a, b) {
          if (a.displayName.toUpperCase() > b.displayName.toUpperCase()) {
            return 1;
          }
          if (a.displayName.toUpperCase() < b.displayName.toUpperCase()) {
            return -1;
          }
          return 0;
        })
        .map((u) => Object({ id: u.uid, text: u.displayName }))
        .filter((value, index, self) => {
          if (!this.filtroUsuariosZona) {
            return this.zoneUserActive.includes(value.id);
            // && self.findIndex((f) => f.text == value.text) === index;
          }
          return self.findIndex((f) => f.text == value.text) === index;
        })
    );
  }

  getUserListUid(uid) {
    return (
      this.user_list &&
      this.user_list
        .filter((f) => {
          return this.utilsService.search(f, "uid", uid);
        })
        .filter((f) => {
          return f.disabled !== true;
        })
        .map((u) => Object({ id: u.uid, text: u.displayName }))
        .filter(
          (value, index, self) =>
            self.findIndex((f) => f.text == value.text) === index
        )
    );
  }

  get getFormsList() {
    return (
      this.customersList &&
      this.customersList
        .filter((f) => {
          return this.utilsService.search(f, "name", "");
        })
        .filter((f) => {
          return f.status !== "D";
        })
        .sort(function (a, b) {
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
          }
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          return 0;
        })
        .map((u) => Object({ id: u.id, text: u.name }))
        .filter(
          (value, index, self) =>
            self.findIndex((f) => f.text == value.text) === index
        )
    );
  }

  getFormsListID(id) {
    console.log("getFormsListID>>>>>>>", id);

    return (
      this.customersList &&
      this.customersList
        .filter((f) => {
          return this.utilsService.search(f, "id", id);
        })
        .filter((f) => {
          return f.status !== "D";
        })
        .map((u) => Object({ id: u.id, text: u.name }))
        .filter(
          (value, index, self) =>
            self.findIndex((f) => f.text == value.text) === index
        )
    );
  }

  get getFormsListEvents() {
    return (
      this.events_list &&
      this.events_list
        .filter((f) => {
          return f.active === true;
        })
        .sort(function (a, b) {
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
          }
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          return 0;
        })
        .map((u) => Object({ id: u.uid, text: u.name }))
        .filter(
          (value, index, self) =>
            self.findIndex((f) => f.text == value.text) === index
        )
    );
  }

  getFormsListEventsuid(id) {
    return (
      this.events_list &&
      this.events_list
        .filter((f) => {
          return this.utilsService.search(f, "uid", id);
        })
        .filter((f) => {
          return f.active === true;
        })
        .map((u) => Object({ id: u.uid, text: u.name }))
        .filter(
          (value, index, self) =>
            self.findIndex((f) => f.text == value.text) === index
        )
    );
  }

  get getCategoriesList() {
    return (
      this.categories_list &&
      this.categories_list
        .filter((f) => {
          return this.utilsService.search(f, "firebase_id", "");
        })
        .map((u) => Object({ id: u.firebase_id, text: u.name }))
        .filter(
          (value, index, self) =>
            self.findIndex((f) => f.text == value.text) === index
        )
    );
  }

  get getSubCategoriesList() {
    return (
      this.subcategories_list_selected &&
      this.subcategories_list_selected
        .map((u) =>
          Object({ id: u.firebase_id, text: u.name, firebase_id: u.id })
        )
        .filter(
          (value, index, self) =>
            self.findIndex((f) => f.text == value.text) === index
        )
    );
  }

  get getEquipmentsList() {
    return (
      this.equipments &&
      this.equipments.map((u) =>
        Object({
          id: u.firebase_id,
          text: u.qr,
          firebase_id: u.id,
        })
      )
    );
  }

  getEquipmentsListId(id) {
    console.log("EQUIPMENT ID", id);
    return (
      this.equipments &&
      this.equipments
        .filter((f) => {
          return this.utilsService.search(f, "firebase_id", id);
        })
        .map((u) =>
          Object({
            id: u.firebase_id,
            text: u.qr,
            firebase_id: u.id,
          })
        )
    );
  }

  getCategoriesuid(id) {
    return (
      this.categories_list &&
      this.categories_list
        .filter((f) => {
          return this.utilsService.search(f, "firebase_id", id);
        })
        .map((u) => Object({ id: u.firebase_id, text: u.name }))
    );
  }

  getSubCategoriesuid(id) {
    return (
      this.subcategories_list &&
      this.subcategories_list
        .filter((f) => {
          return this.utilsService.search(f, "firebase_id", id);
        })
        .map((u) =>
          Object({ id: u.firebase_id, text: u.name, firebase_id: u.id })
        )
        .filter(
          (value, index, self) =>
            self.findIndex((f) => f.text == value.text) === index
        )
    );
  }

  tipos2: TiposMarker[] = [
    { id: "1", text: "pruebas1" },
    { id: "2", text: "pruebas2" },
    { id: "3", text: "pruebas3" },
  ];
  zoneSelect = [];

  form: FormGroup;
  validation_msg;
  latitude: number = null;
  longitude: number = null;
  zoom: number = 12;
  address: string = "";
  scheduled_estimated_duration: string = "";
  isUtc: boolean = false;
  manual_addres: any = null;
  private geoCoder;
  btn_save = false;
  min: string;
  programmingList: programmingObj[];
  periodicity: any[];
  programming: any = null;
  public searchElementRef: ElementRef;
  editperiodiciti = false;

  dir: any = {
    scheduled_street: "",
    scheduled_colony: "",
    scheduled_num_ext: "",
    scheduled_num_int: "",
    scheduled_postal_address: "",
    scheduled_state: "",
    scheduled_town: "",
  };

  expirationDate: any;
  expirationHour: any;
  currentTimeZone: any;
  fiel_classification = false;
  fiel_subclassification = false;
  fiel_classification_title = "";
  fiel_subclassification_title = "";
  constructor(
    public authService: AuthService,
    private dialogRef: MatDialogRef<ProgrammingNewAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public eventsService: EventsService,
    private spinner: NgxSpinnerService,
    public utilsService: UtilsService,
    public userService: UserService,

    public customerService: CustomersService,
    public translateService: TranslateService,
    private programingservice: ProgramingService,
    private dialog: MatDialog,
    private modalGeneric: ModalGenericService,
    public storageService: StorageService,
    private router: Router,
    private timezoneConversionService: TimezoneConversionService,
    public classificationService: ClassificationService,
    public equipmentService: EquipmentService,
    private cd: ChangeDetectorRef
  ) {
    this.currentTimeZone = this.storageService.getTimeZoneConfig();
    console.log("GG Before 2", data.customer_uid);
    this.filterEquipmentByUId = data.customer_uid ? data.customer_uid : "all";
  }

  async getAllZoneFirebase() {
    const filterZoneActives: any[] = [];
    this.zoneSelect = [];
    const scheduled_zone = !!this.programming
      ? this.programming.scheduled_zone
      : [];
    // console.log('selected zones', scheduled_zone);
    try {
      let firebase_tenan = await this.authService.getTenancyParams();
      const querySnapshot = await firebase_tenan.collection("zones").get();
      console.log(`query data ${!querySnapshot.empty}`);
      querySnapshot.forEach((doc) => {
        const zoneDoc = doc.data();
        const dataZone = {
          id: doc.id,
          text: zoneDoc.name,
        };
        this.zone_list_selected.push({ ...dataZone });

        if (scheduled_zone.includes(dataZone.id)) {
          filterZoneActives.push(new FormControl(dataZone).value);
          this.zoneSelect.push({ ...dataZone });
        }
      });

      this.form.controls.scheduled_zone.setValue(filterZoneActives);
    } catch (error) {
      console.log(error);
    }
    console.log("zone list", this.zone_list_selected);
  }

  async ngOnInit() {
    this.setForm();
    if (this.authService.customerDB != null) {
      this.equipmentService.customerDB = this.authService.customerDB;
      this.equipmentService.customerSelected =
        this.authService.customerSelected;

      this.eventsService.customerDB = this.authService.customerDB;
      this.eventsService.customerSelected = this.authService.customerSelected;
      this.spinner.show();
      await this.classificationService
        .getDataClassification()
        .subscribe((response) => {
          //console.log(response.data[0].categories);

          if (response.data.length > 0) {
            this.fiel_classification_title = response.data[0].name_category;
            this.fiel_subclassification_title =
              response.data[0].name_subcategory;

            let data_categories: any = response.data[0].categories;
            this.categories_list = data_categories;

            let data_array_subcategories: any[] = [];
            data_categories.forEach((element) => {
              element.subcategories.forEach((e) => {
                data_array_subcategories.push({
                  firebase_id: e.firebase_id,
                  name: e.name,
                  id: element.firebase_id,
                });
              });
            });
            this.subcategories_list = data_array_subcategories;
          }

          this.onInit();
        });
    }
  }

  async onInit() {
    this.customerService.customerDB = this.authService.customerDB;
    this.customerService.customerSelected = this.authService.customerSelected;
    this.spinner.show();
    console.log("this.data", this.data);

    if (this.data.id != 0) {
      console.log("data.id", this.data.id);
      let Parameters = null;
      Parameters = {
        id: this.data.id,
      };
      this.programingservice.customerDB = this.authService.customerDB;
      this.programingservice.customerSelected =
        this.authService.customerSelected;
      await this.programingservice
        .getScheduledEventsFiltersNewCalendar(Parameters)
        .subscribe(
          (response) => {
            console.log("response>>>>>>>>>>>>>>>>>>>", response);
            this.filtroUsuariosZona = true;
            this.programmingList = response.data.map((r) => {
              return { ...r, ...{ selected: false } };
            });
            this.programming = this.programmingList[0];

            this.dir = this.programming;
            console.log(
              "-- this.programming.scheduled_clasification ",
              this.programming
            );
            this.form.controls.scheduled_name_event.setValue(
              this.programming.scheduled_name_event
                ? this.programming.scheduled_name_event
                : ""
            );

            this.form.controls.scheduled_estimated_duration_hours.setValue(
              this.programming.scheduled_estimated_duration_hours
                ? this.programming.scheduled_estimated_duration_hours.toString()
                : "0"
            );

            this.isUtc = this.programming.is_utc;
            this.scheduled_estimated_duration = this.programming
              .scheduled_estimated_duration
              ? this.programming.is_utc
                ? this.timezoneConversionService.getTimeConvertedTimeZone(
                    this.programming.scheduled_estimated_duration,
                    this.currentTimeZone.reference
                  ).hora24
                : this.programming.scheduled_estimated_duration
              : "";
            console.log(
              "estimated_duration",
              this.programming.scheduled_estimated_duration_minutes
            );
            this.form.controls.scheduled_estimated_duration_minutes.setValue(
              this.programming.scheduled_estimated_duration_minutes.toString()
                ? this.programming.scheduled_estimated_duration_minutes
                : "30"
            );

            if (
              this.programming.scheduled_clasification != null &&
              this.programming.scheduled_clasification != "0"
            ) {
              this.fiel_classification = true;
              let scheduled_clasification = this.getCategoriesuid(
                this.programming.scheduled_clasification
              );
              console.log(
                "scheduled_clasification >>>>>>>>>>",
                scheduled_clasification
              );
              this.form.controls.scheduled_clasification.setValue(
                scheduled_clasification
              );
            }
            console.log(
              "scheduled_assigned_to>>>>>>>",
              this.programming.scheduled_assigned_to
            );
            if (this.programming.scheduled_assigned_to != "") {
              let scheduled_assigned_to = this.getUserListUid(
                this.programming.scheduled_assigned_to
              );
              console.log(
                "scheduled_assigned_to>>>>>>>",
                scheduled_assigned_to
              );
              this.userSel = scheduled_assigned_to;
              this.form.controls.scheduled_assigned_to.setValue(
                scheduled_assigned_to
              );
            }

            this.form.controls.scheduled_periodicity.setValue(
              this.programming.scheduled_periodicity
            );
            var time: any = this.programming.scheduled_programming_date;
            var dt = new Date(time._seconds * 1000);
            this.min = this.fechainarray(
              new Date(
                new Date(time._seconds * 1000).toLocaleString("en-US", {
                  timeZone: this.currentTimeZone.reference,
                })
              ),
              2
            );
            console.log(
              "this.min >>>>>>>>>>>>>>>>>>",
              moment().format("YYYY-MM-DD")
            );
            console.log("this.min >>>>>>>>>>>>>>>>>>", this.min);

            var time2: any = this.programming.scheduled_limit_date;
            var dt2 = new Date(time2._seconds * 1000);
            this.form.controls.scheduled_date_programming.setValue(this.min);
            this.form.controls.scheduled_date_programming_original.setValue(
              this.min
            );
            this.min = moment().format("YYYY-MM-DD");

            console.log(
              "this.programming.is_utc estimated_duration",
              this.programming.is_utc
            );
            console.log(
              "this.programming.is_utc estimated_duration",
              this.currentTimeZone.reference
            );
            console.log(
              "this.programming.is_utc estimated_duration",
              this.programming.scheduled_hour_since
            );
            this.form.controls.scheduled_hour_since.setValue(
              this.programming.is_utc
                ? this.timezoneConversionService.getTimeConvertedTimeZone(
                    dt,
                    this.currentTimeZone.reference
                  ).hora24
                : this.programming.scheduled_hour_since
            );
            console.log(
              "this.programming.is_utc estimated_duration",
              this.programming.is_utc
            );
            console.log(
              "this.programming.is_utc estimated_duration",
              this.currentTimeZone.reference
            );
            console.log(
              "this.programming.scheduled_hour_limit estimated_duration",
              this.programming.scheduled_hour_limit
            );
            this.form.controls.scheduled_hour_limit.setValue(
              this.programming.is_utc
                ? this.timezoneConversionService.getTimeConvertedTimeZone(
                    dt2,
                    this.currentTimeZone.reference
                  ).hora24
                : this.programming.scheduled_hour_limit
            );
            this.form.controls.scheduled_expiration_date.setValue(
              this.programming.scheduled_expiration_date
            );

            console.log(
              "scheduled_type_event 1",
              this.events_list,
              this.programming
            );
            let scheduled_type_event = this.getFormsListEventsuid(
              this.programming.scheduled_type_event
            );
            this.tipoSel = scheduled_type_event;
            this.form.controls.scheduled_type_event.setValue(
              scheduled_type_event
            );

            this.filterEquipmentByUId = this.programming.scheduled_client_uuid;
            let scheduled_client_uuid = this.getFormsListID(
              this.programming.scheduled_client_uuid
            );
            this.customSel = scheduled_client_uuid;
            console.log("customSel 1", moment());
            this.form.controls.scheduled_client_uuid.setValue(
              scheduled_client_uuid
            );

            this.form.controls.scheduled_instructions.setValue(
              this.programming.scheduled_instructions
            );
            this.form.controls.scheduled_address.setValue(
              this.programming.scheduled_address
            );
            this.form.controls.scheduled_periodicity_original.setValue(
              this.programming.scheduled_periodicity
            );
            this.form.controls.firebase_id.setValue(
              this.programming.firebase_id
            );
            this.form.controls.scheduled_mother_serie.setValue(
              this.programming.scheduled_mother_serie
            );
            this.form.controls.order_number.setValue(
              this.programming.order_number
            );
            this.setExpirationDate();
            this.expirationHour = moment(
              this.form.controls.scheduled_hour_limit.value,
              "h:mm a"
            );
            time = this.programming.scheduled_date_creation;
            dt = new Date(time._seconds * 1000);
            let scheduled_date_creation = this.fechainarray(dt, 3);
            console.log("scheduled_date_creation", scheduled_date_creation);
            this.form.controls.scheduled_date_creation.setValue(
              scheduled_date_creation
            );
            this.field_asigneed_to = false;

            this.getEquipments();

            if (
              this.programming.scheduled_mother_serie !== null &&
              this.programming.scheduled_mother_serie !== ""
            ) {
              if (
                this.programming.firebase_id !=
                this.programming.scheduled_mother_serie
              ) {
                console.log(
                  "this.programming.firebase_id",
                  this.programming.firebase_id
                );
                console.log(
                  "scheduled_mother_serie",
                  this.programming.scheduled_mother_serie
                );
                this.editperiodiciti = true;
              }
            }
            this.address = this.programming.scheduled_address;
            console.log("this.programming", this.programming);
            this.setDebounceInInputsTime();
            setTimeout(() => {
              this.spinner.hide();
            }, 3000);
          },
          (error) => {
            console.log("error", error);
            this.spinner.hide();
          }
        );
    } else {
      this.setDebounceInInputsTime();
      this.getEquipments();
    }

    await this.eventsService.getEventsList().subscribe((response) => {
      this.events_list = response.data.map((r) => {
        return { ...r, ...{ selected: false } };
      });
      console.log("scheduled_type_event 2", this.events_list, this.programming);

      if (this.programming != null && this.programming != undefined) {
        let scheduled_type_event = this.getFormsListEventsuid(
          this.programming.scheduled_type_event
        );
        this.tipoSel = scheduled_type_event;
        this.form.controls.scheduled_type_event.setValue(scheduled_type_event);
      }
    });

    await this.customerService.getCustomersList().subscribe(
      (response) => {
        if (response) {
          this.customersList = response.data.map((r) => {
            return { ...r, ...{ selected: false } };
          });

          console.log("customSel 2", moment(), this.programming);
          if (this.programming != null && this.programming != undefined) {
            let scheduled_client_uuid = this.getFormsListID(
              this.programming.scheduled_client_uuid
            );
            this.customSel = scheduled_client_uuid;

            this.form.controls.scheduled_client_uuid.setValue(
              scheduled_client_uuid
            );
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );

    await this.userService.getUsersList().subscribe((response) => {
      console.log("users", response);
      this.user_list = response.data.map((r) => {
        return { ...r, ...{ selected: false } };
      });
      this.user_list_original = response.data.map((r) => {
        return { ...r, ...{ selected: false } };
      });
      if (this.data.id == 0) this.spinner.hide();
    });

    this.programingservice.getPeriodicty({}).subscribe(
      (response: any) => {
        console.log("response.data", response.periodicity);
        this.periodicity = response.periodicity;
      },
      (error) => {
        console.log("error", error);
      }
    );

    setTimeout(() => {
      // this.getAllZoneFirebase();
      this.getZoneByUserId();
    }, 2000);
  }

  // onchangeFilterUsers() {
  //   this.filtroUsuariosZona = !this.filtroUsuariosZona;

  //   if (this.filtroUsuariosZona) {
  //     this.listUSersConfig = this.listUSersConfig.filter(item => {
  //       return this.zoneUserActive.includes(item.uid);
  //     });
  //   } else {
  //     this.listUSersConfig = this.getUserList;
  //   }
  // }

  setDateTimeInfoFromCalendar() {
    if (this.data.date) {
      console.log("this.data.date", this.data.date);

      const valDate = moment(this.data.date, "YYYY-MM-DD HH:mm"); //.format("YYYY-MM-DD");
      console.log("valDate", valDate.format("YYYY-MM-DD"));
      console.log("this.data.date", moment().format("YYYY-MM-DD"));
      console.log("valDate", valDate.isBefore(moment()));

      if (valDate >= moment()) {
        this.min = moment(this.data.date).format("YYYY-MM-DD");
        this.form.controls.scheduled_date_programming.setValue(this.min);
        this.form.controls.scheduled_date_programming_original.setValue(
          this.min
        );
        console.log(
          "moment(this.data.date).format()",
          moment(this.data.date).format("HH:mm")
        );
        this.expirationHour = moment(this.data.date, "h:mm a");
        this.form.controls.scheduled_hour_since.setValue(
          moment(this.data.date).format("HH:mm")
        );
      }
    }
  }

  onChangeCustomer(event) {
    this.equipmentSelected = [];
    this.equipments = [];

    if (event.length == 0) {
      this.filterEquipmentByUId = "all";
      this.getEquipments();
    } else {
      let event_find = event[0] != undefined ? event[0] : event;

      if (event_find) {
        this.filterEquipmentByUId = event_find.id;
      }
    }

    this.getEquipments();

    /**
     console.log('FILTER STATUS ', this.filterEquipmentByUId);
    if (event === [] || event === undefined) {
    } else if (
      this.customSel !== null &&
      this.customSel !== undefined &&
      this.customSel !== [] &&
      this.customSel.length > 0
    ) {
      console.log('GG not work');
      console.log(event);
      //console.log(this.customSel[0]['id']);
      //console.log('Status from var ', event[0].id);
      //this.filterEquipmentByUId = this.customSel[0]['id'];

      if (event[0] !== undefined) {
        if (event[0].id) {
          //event[0].id;
        }
      }

      //this.customSel[0]['id'];
      this.getEquipments();
    }
     */
    console.log("FILTER STATUS 2", this.filterEquipmentByUId);
  }

  getEquipments() {
    console.log("GG", this.filterEquipmentByUId);
    if (
      Object.keys({
        customer_id: this.filterEquipmentByUId,
      }).length > 0 &&
      this.filterEquipmentByUId !== undefined
    ) {
      console.log("REQUEST BODY ", {
        customer_id: this.filterEquipmentByUId,
      });

      this.equipmentService
        .getEquipments({
          customer_id: this.filterEquipmentByUId,
        })
        .subscribe((equipmentsResponse) => {
          this.equipments = equipmentsResponse.data;

          if (this.programming !== null) {
            let scheduled_equipmentSelected = this.getEquipmentsListId(
              this.programming.scheduled_equipment_uid
            );

            this.equipmentSelected = scheduled_equipmentSelected;
          }
        });
    }
  }

  onchangeEvent(event) {
    let event_find = event;
    let bandera = true;

    console.log("onchangeClasfication", event);
    if (event.length == 0) return false;
    else {
      bandera = event[0] != undefined ? false : true;
      event_find = event[0] != undefined ? event[0] : event;
    }

    console.log("onchangeClasfication event_find", event_find);

    let find = this.events_list.find((x) => x.uid === event_find.id);
    console.log("onchangeClasfication><", find);
    if (find == undefined) return false;

    this.fiel_classification = false;

    if (this.programming == null) {
      this.fiel_subclassification = false;
    }
    if (find.active_category) {
      this.fiel_classification = true;
      if (this.programming == null) {
        this.fiel_subclassification = false;
      }
      this.form.controls.scheduled_clasification.setValidators(
        Validators.required
      );
    } else {
      this.fiel_classification = false;
      this.fiel_subclassification = false;
      this.form.controls.scheduled_clasification.setValidators([]);
    }
    this.user_list = [];
    this.user_list = this.user_list_original;

    if (find.users_autorized != null) {
      if (find.users_autorized.all == false) {
        let user_list = this.user_list_original.filter(function (element) {
          return find.users_autorized.user_uid.includes(element.uid);
        });
        console.log("onchangeEvent", user_list, find.users_autorized.user_uid);
        this.user_list = user_list;
      }
    }

    this.field_asigneed_to = false;
  }

  onchangeSubClasfication(event) {
    let event_find = event;
    if (event_find.length == 0) {
      this.form.controls.scheduled_subclasification.setValue([
        { id: "0", text: "" },
      ]);
      this.form.controls.scheduled_subclasification.setValidators([]);
      return false;
    }
  }
  onchangeZone(event) {
    let event_find = event;
    if (event_find.length == 0) {
      this.form.controls.scheduled_zone.setValue([]);
      this.form.controls.scheduled_zone.setValidators([]);
      return false;
    }
  }

  onchangeClasfication(event) {
    let event_find = event;
    let bandera = true;

    console.log("onchangeClasfication- - ", event_find);
    if (event_find.length == 0) {
      this.form.controls.scheduled_clasification.setValue([
        { id: "0", text: "" },
      ]);
      this.form.controls.scheduled_clasification.setValidators([]);
      this.form.controls.scheduled_subclasification.setValue([
        { id: "0", text: "" },
      ]);
      this.form.controls.scheduled_subclasification.setValidators([]);
      return false;
    } else {
      bandera = event_find[0] != undefined ? false : true;
      event_find = event_find[0] != undefined ? event_find[0] : event_find;
    }

    this.fiel_subclassification = false;

    this.subcategories_list_selected = this.subcategories_list
      .filter((x) => x.id === event_find.id)
      .map((u) =>
        Object({ id: u.firebase_id, text: u.name, firebase_id: u.id })
      );

    if (bandera) {
      this.form.controls.scheduled_subclasification.setValue(null);
    }

    if (this.subcategories_list_selected.length > 0) {
      this.fiel_subclassification = true;

      console.log(
        "this.programming.scheduled_subclasification ,, ",
        this.programming.scheduled_subclasification
      );
      if (
        bandera == false &&
        this.programming != null &&
        this.programming.scheduled_subclasification.toString() != "0"
      ) {
        let scheduled_subclasification = this.getSubCategoriesuid(
          this.programming.scheduled_subclasification
        );
        console.log(
          "onchangeClasfication >>>>>>>>>>",
          scheduled_subclasification,
          this.fiel_subclassification
        );
        this.form.controls.scheduled_subclasification.setValue(
          scheduled_subclasification
        );
        this.fiel_subclassification = true;
      }
    }
  }

  private fechainarray(date, tipo) {
    let spli = null;
    if (tipo == 1) {
      let formatted_date_fin =
        date.getDate().toString().length == 1
          ? "0" + date.getDate() + "/"
          : date.getDate() + "/";
      if ((date.getMonth() + 1).toString().length == 1) {
        formatted_date_fin += "0" + (date.getMonth() + 1);
      } else {
        formatted_date_fin += date.getMonth() + 1;
      }
      formatted_date_fin += "/" + date.getFullYear();
      spli = formatted_date_fin.split("/");
    } else if (tipo === 2) {
      spli = Moment(date).format("Y-MM-DD");
    } else {
      spli = Moment(date).format("Y-MM-DD HH:mm:ss");
    }
    return spli;
  }

  actualdatevalidation() {
    let date = new Date();
    date.setDate(date.getDate());

    let formatted_date_fin =
      date.getDate().toString().length == 1
        ? "0" + date.getDate() + "/"
        : date.getDate() + "/";

    console.log(formatted_date_fin);
    if ((date.getMonth() + 1).toString().length == 1) {
      formatted_date_fin += "0" + (date.getMonth() + 1);
    } else {
      formatted_date_fin += date.getMonth() + 1;
    }
    formatted_date_fin += "/" + date.getFullYear();
    let spli = formatted_date_fin.split("/");
    console.log(spli);
    this.form.controls.scheduled_date_programming.setValue(
      spli[2] + "-" + spli[1] + "-" + spli[0]
    );
    this.min = spli[2] + "-" + spli[1] + "-" + spli[0];
    this.expirationHour = moment(date, "h:mm a");
    this.form.controls.scheduled_hour_since.setValue(
      Moment(date).format("HH:mm")
    );
    this.form.controls.scheduled_hour_limit.setValue(
      Moment(date).format("HH:mm")
    );

    this.setDateTimeInfoFromCalendar();
    this.setExpirationDate();
  }

  setExpirationDate() {
    this.expirationDate = moment(
      this.form.controls.scheduled_date_programming.value,
      "YYYY-MM-DD"
    );
    this.expirationDate.add(
      this.form.controls.scheduled_expiration_date.value,
      "d"
    );
  }

  setForm() {
    let date = new Date();
    date.setDate(date.getDate());

    let formatted_date_fin =
      date.getDate().toString().length == 1
        ? "0" + date.getDate() + "/"
        : date.getDate() + "/";

    console.log(formatted_date_fin);
    if ((date.getMonth() + 1).toString().length == 1) {
      formatted_date_fin += "0" + (date.getMonth() + 1);
    } else {
      formatted_date_fin += date.getMonth() + 1;
    }
    formatted_date_fin += "/" + date.getFullYear();
    let spli = formatted_date_fin.split("/");
    console.log(spli);
    this.form = new FormGroup({
      scheduled_type_event: new FormControl(
        this.formulario.scheduled_type_event,
        Validators.required
      ),
      scheduled_name_event: new FormControl(
        this.formulario.scheduled_name_event,
        Validators.required
      ),
      scheduled_assigned_to: new FormControl(
        this.formulario.scheduled_assigned_to,
        Validators.required
      ),
      scheduled_client_uuid: new FormControl(
        this.formulario.scheduled_client_uuid
      ),
      scheduled_equipment_uid: new FormControl(
        this.formulario.scheduled_equipment_uid
      ),
      scheduled_periodicity: new FormControl(
        this.formulario.scheduled_periodicity,
        Validators.required
      ),
      scheduled_estimated_duration: new FormControl(
        this.formulario.scheduled_estimated_duration,
        Validators.required
      ),
      scheduled_estimated_duration_hours: new FormControl(
        this.formulario.scheduled_estimated_duration_hours,
        Validators.required
      ),
      scheduled_estimated_duration_minutes: new FormControl(
        this.formulario.scheduled_estimated_duration_minutes,
        Validators.required
      ),
      scheduled_clasification: new FormControl(
        this.formulario.scheduled_clasification
      ),
      scheduled_subclasification: new FormControl(
        this.formulario.scheduled_subclasification
      ),
      scheduled_zone: new FormControl(this.formulario.scheduled_zone),
      scheduled_date_limit: new FormControl(""),
      id_user: new FormControl(""),
      id_event: new FormControl(""),
      order_name: new FormControl(""),
      user_name: new FormControl(""),
      latitude: new FormControl(""),
      longitude: new FormControl(""),
      scheduled_address: new FormControl(""),
      scheduled_periodicity_original: new FormControl(""),
      firebase_id: new FormControl(""),
      one_all: new FormControl("one"),
      order_number: new FormControl(""),
      scheduled_mother_serie: new FormControl(""),
      scheduled_date_programming: new FormControl(null, Validators.required),
      scheduled_date_programming_original: new FormControl(null),
      scheduled_date_creation: new FormControl(null),
      scheduled_hour_since: new FormControl(
        this.formulario.scheduled_hour_since,
        Validators.required
      ),
      scheduled_hour_limit: new FormControl(
        this.formulario.scheduled_hour_limit,
        Validators.required
      ),
      scheduled_expiration_date: new FormControl(1),
      scheduled_instructions: new FormControl(
        this.formulario.scheduled_instructions
      ),

      scheduled_colony: new FormControl(""),

      scheduled_num_ext: new FormControl(""),

      scheduled_num_int: new FormControl(""),

      scheduled_postal_address: new FormControl(""),

      scheduled_state: new FormControl(""),

      scheduled_street: new FormControl(""),

      scheduled_town: new FormControl(""),
    });
    this.validationMsg();
    this.actualdatevalidation();
  }

  setDebounceInInputsTime() {
    this.form
      .get("scheduled_hour_since")
      .valueChanges.pipe(
        takeUntil(this.onDestroy),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((val) => {
        this.changehoursice(val);
      });
    this.form
      .get("scheduled_hour_limit")
      .valueChanges.pipe(
        takeUntil(this.onDestroy),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((val) => {
        this.changetimelimit();
      });
  }

  validationMsg() {
    this.validation_msg = {
      scheduled_type_event: [
        {
          type: "required",
          message: "Por favor, ingresa el tipo de actividad.",
        },
      ],
      scheduled_clasification: [
        {
          type: "required",
          message: "Por favor, ingresa la categoria.",
        },
      ],
      scheduled_name_event: [
        {
          type: "required",
          message: "Por favor, ingresa el nombre de la actividad.",
        },
      ],
      scheduled_assigned_to: [
        {
          type: "required",
          message: "Por favor, ingresa el campo asignado a.",
        },
      ],
      scheduled_client_uuid: [
        {
          type: "required",
          message: "Por favor, ingresa el cliente.",
        },
      ],

      scheduled_equipment_uid: [
        { type: "requiered", message: "Por favor, ingresa el equipo" },
      ],

      scheduled_periodicity: [
        {
          type: "required",
          message: "Por favor, ingresa la periodicidad.",
        },
      ],
      scheduled_date_programming: [
        {
          type: "required",
          message: "Por favor, ingresa la fecha de programación.",
        },
      ],
      scheduled_hour_since: [
        {
          type: "required",
          message: "Por favor, ingresa la hora de inicio.",
        },
      ],
      scheduled_hour_limit: [
        {
          type: "required",
          message: "Por favor, ingresa la hora límite.",
        },
      ],
      scheduled_expiration_date: [
        {
          type: "required",
          message: "Por favor, ingresa la fecha de vencimiento requerida.",
        },
      ],

      scheduled_instructions: [
        {
          type: "required",
          message: "Por favor, ingresa las instrucciones son requeridas.",
        },
      ],
      scheduled_estimated_duration: [
        {
          type: "required",
          message: "Por favor, ingresa fecha estimada.",
        },
      ],

      scheduled_estimated_duration_hours: [
        {
          type: "required",
          message: "Por favor, ingresa hora estimada.",
        },
      ],
      scheduled_estimated_duration_minutes: [
        {
          type: "required",
          message: "Por favor, ingresa los minutos estimados.",
        },
      ],
    };
  }
  addMap() {
    const dialogRef = this.dialog
      .open(ProgrammingSearchMapComponent)
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp: any) => {
        if (resp.button == "aceptar") {
          this.form.controls.latitude.setValue(resp.latitude);
          this.form.controls.longitude.setValue(resp.longitude);
          this.form.controls.scheduled_address.setValue(resp.address);
          this.longitude = resp.longitude;
          this.latitude = resp.latitude;
          this.address = resp.address;
        }
      });
  }
  addAddress() {
    const dialogRef = this.dialog
      .open(ProgrammingAddressComponent, {
        data: { id: this.data.id, dir: this.dir },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp: any) => {
        if (resp.button == "aceptar") {
          this.address = resp.address;
          this.manual_addres = resp.form;
          this.form.controls.scheduled_address.setValue(resp.address);
          this.form.controls.latitude.setValue(
            resp.latitudes ? resp.latitudes.lat : ""
          );
          this.form.controls.longitude.setValue(
            resp.latitudes ? resp.latitudes.lng : ""
          );
        }
      });
  }
  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
      });
    }
  }
  public closeMe() {
    this.dialogRef.close("cerrar");
  }
  public onAceptar() {
    this.submit = true;

    this.form.controls.scheduled_name_event.markAsTouched();
    this.form.controls.scheduled_assigned_to.markAsTouched();
    this.form.controls.scheduled_periodicity.markAsTouched();
    this.form.controls.scheduled_date_programming.markAsTouched();
    this.form.controls.scheduled_hour_since.markAsTouched();
    this.form.controls.scheduled_hour_limit.markAsTouched();
    this.form.controls.scheduled_clasification.markAsTouched();
    this.form.controls.scheduled_expiration_date.markAsTouched();
    this.form.controls.scheduled_equipment_uid.markAsTouched();
    this.form.controls.scheduled_estimated_duration.markAsTouched();
    this.form.controls.scheduled_estimated_duration_hours.markAsTouched();
    this.form.controls.scheduled_estimated_duration_minutes.markAsTouched();
    this.form.controls.scheduled_zone.markAsTouched();
    var scheduled_expiration_date = 0;
    if (this.form.controls.scheduled_expiration_date.value != null) {
      scheduled_expiration_date =
        this.form.controls.scheduled_expiration_date.value;
    }

    let days = scheduled_expiration_date > 0 ? scheduled_expiration_date : 0;

    if (days >= 0) {
      let date = new Date(this.form.controls.scheduled_date_programming.value);

      date.setDate(date.getDate() + (+days + 1));
      console.log("Dys", date);
      console.log("Dys", days);
      let formatted_date_fin =
        date.getDate().toString().length == 1
          ? "0" + date.getDate() + "/"
          : date.getDate() + "/";

      console.log(formatted_date_fin);
      if ((date.getMonth() + 1).toString().length == 1) {
        formatted_date_fin += "0" + (date.getMonth() + 1);
      } else {
        formatted_date_fin += date.getMonth() + 1;
      }
      formatted_date_fin += "/" + date.getFullYear();

      let spli = formatted_date_fin.split("/");

      this.form.controls.scheduled_date_limit.setValue(
        spli[2] + "-" + spli[1] + "-" + spli[0]
      );
      console.log(this.form.controls.scheduled_date_programming.value);
      console.log(this.form.controls.scheduled_date_limit.value);
    }
    let scheduled_type_event =
      this.form.controls.scheduled_type_event.value[0].id;
    console.log(
      "this.form.controls.scheduled_type_event.value",
      this.form.controls.scheduled_type_event.value
    );
    // this.form.controls.scheduled_type_event.setValue(scheduled_type_event);
    this.form.controls.id_event.setValue(scheduled_type_event);
    this.form.controls.id_user.setValue(
      this.authService.customerDB.app.auth().currentUser.uid
    );
    this.form.controls.order_name.setValue(
      this.form.controls.scheduled_name_event.value
    );

    let user_selected = this.user_list.find(
      (element) =>
        element.uid == this.form.controls.scheduled_assigned_to.value[0].id
    );
    this.form.controls.user_name.setValue(user_selected.displayName);

    if (
      this.form.controls.scheduled_client_uuid.value == null ||
      this.form.controls.scheduled_client_uuid.value.length == 0
    ) {
      this.form.controls.scheduled_client_uuid.setValue([
        { id: "0", text: "" },
      ]);
      this.form.controls.scheduled_client_uuid.setValidators([]);
    }

    if (
      this.form.controls.scheduled_equipment_uid.value == null ||
      this.form.controls.scheduled_equipment_uid.value.length == 0
    ) {
      this.form.controls.scheduled_equipment_uid.setValue([
        { id: "0", text: "" },
      ]);
      this.form.controls.scheduled_equipment_uid.setValidators([]);
    }

    if (this.form.controls.scheduled_clasification.value == null) {
      this.form.controls.scheduled_clasification.setValue([
        { id: "0", text: "" },
      ]);
      this.form.controls.scheduled_clasification.setValidators([]);
    }
    if (this.form.controls.scheduled_subclasification.value == null) {
      this.form.controls.scheduled_subclasification.setValue([
        { id: "0", text: "" },
      ]);
      this.form.controls.scheduled_subclasification.setValidators([]);
    }
    if (this.form.controls.scheduled_zone.value == null) {
      this.form.controls.scheduled_zone.setValue([]);
      this.form.controls.scheduled_zone.setValidators([]);
    } else {
      const listZoneSelected: string[] =
        this.form.controls.scheduled_zone.value.map((item) => item.id) || [];
      this.form.controls.scheduled_zone.setValue(listZoneSelected);
    }

    this.form.controls.scheduled_estimated_duration.setValue("0");

    console.log(this.form.value, this.form.valid);
    console.log(`control zona`, this.form.controls.scheduled_zone.value);
    if (this.form.valid) {
      if (this.data.id == 0) {
        this.spinner.show();
        this.onAdd();
      } else {
        let mensaje = "";
        let all = false;
        if (
          this.form.controls.scheduled_periodicity.value == "notrepeat" &&
          this.form.controls.scheduled_periodicity_original.value != "notrepeat"
        ) {
          mensaje =
            "Se perderán las actividades programadas en fechas futuras.";
          all = true;
        }
        let periodicity =
          this.form.controls.scheduled_periodicity_original.value ===
          "notrepeat"
            ? "notrepeat"
            : this.form.controls.scheduled_periodicity.value;
        console.log("periodicity,", periodicity);
        this.modalGeneric
          .onModelconfirmacionEvent(
            periodicity,
            false, //disableClose
            "¿Está seguro de modificar esta actividad?", //message
            true, // cancelButton
            this.translateService.instant(
              "PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE"
            ), //confimButtonText
            true, // info
            false, //imagen info
            "", //title
            "Si, modificar esta actividad.",
            "Si, modificar todas las actividades de aquí en adelante.",
            mensaje
          )
          .afterClosed()
          .pipe(take(1))
          .subscribe((resp) => {
            console.log(resp);
            if (resp.button === "aceptar" || resp === "aceptar") {
              this.spinner.show();
              if (all) {
                this.form.controls.one_all.setValue("all");
              } else {
                this.form.controls.one_all.setValue(resp.radiodelete);
              }
              this.onedit();
            }
          });
      }
    }
  }
  onAdd() {
    if (this.manual_addres == null) {
      this.form.controls.scheduled_colony.setValue("");
      this.form.controls.scheduled_num_ext.setValue("");
      this.form.controls.scheduled_num_int.setValue("");
      this.form.controls.scheduled_postal_address.setValue("");
      this.form.controls.scheduled_state.setValue("");
      this.form.controls.scheduled_street.setValue("");
      this.form.controls.scheduled_town.setValue("");
    } else {
      this.form.controls.scheduled_colony.setValue(
        this.manual_addres.scheduled_colony
      );
      this.form.controls.scheduled_num_ext.setValue(
        this.manual_addres.scheduled_num_ext
      );
      this.form.controls.scheduled_num_int.setValue(
        this.manual_addres.scheduled_num_int
      );
      this.form.controls.scheduled_postal_address.setValue(
        this.manual_addres.scheduled_postal_address
      );
      this.form.controls.scheduled_state.setValue(
        this.manual_addres.scheduled_state
      );
      this.form.controls.scheduled_street.setValue(
        this.manual_addres.scheduled_street
      );
      this.form.controls.scheduled_town.setValue(
        this.manual_addres.scheduled_town
      );
    }

    console.log(this.form.value);
    this.programingservice
      .saveProgrammingEvent(this.form.value)
      .subscribe((response) => {
        this.spinner.hide();
        console.log("save response", response);
        if (response.statusCode == 200) {
          this.sendSuccess("La actividad se ha programado correctamente.");
        } else {
          this.sendModelError("La actividad no pudo ser programada.");
        }
      });
  }

  onedit() {
    if (this.manual_addres == null) {
      this.form.controls.scheduled_colony.setValue("");
      this.form.controls.scheduled_num_ext.setValue("");
      this.form.controls.scheduled_num_int.setValue("");
      this.form.controls.scheduled_postal_address.setValue("");
      this.form.controls.scheduled_state.setValue("");
      this.form.controls.scheduled_street.setValue("");
      this.form.controls.scheduled_town.setValue("");
    } else {
      this.form.controls.scheduled_colony.setValue(
        this.manual_addres.scheduled_colony
      );
      this.form.controls.scheduled_num_ext.setValue(
        this.manual_addres.scheduled_num_ext
      );
      this.form.controls.scheduled_num_int.setValue(
        this.manual_addres.scheduled_num_int
      );
      this.form.controls.scheduled_postal_address.setValue(
        this.manual_addres.scheduled_postal_address
      );
      this.form.controls.scheduled_state.setValue(
        this.manual_addres.scheduled_state
      );
      this.form.controls.scheduled_street.setValue(
        this.manual_addres.scheduled_street
      );
      this.form.controls.scheduled_town.setValue(
        this.manual_addres.scheduled_town
      );
    }

    this.form.controls.scheduled_estimated_duration.setValue("0");
    console.log(this.manual_addres);
    console.log("this.form.value", this.form.value);
    this.programingservice
      .editProgrammingEvent(this.form.value)
      .subscribe((response) => {
        this.spinner.hide();
        console.log("save response", response);
        if (response.statusCode == 200) {
          this.sendSuccess("La actividad se ha programado correctamente.");
        } else {
          this.sendModelError("La actividad no pudo ser programada.");
        }
      });
  }

  changedateprogramming() {
    let actual = new Date();
    actual.setHours(0, 0, 0, 0);
    let date = new Date(this.form.controls.scheduled_date_programming.value);
    date.setDate(date.getDate() + 1);
    date.setHours(0, 0, 0, 0);
    var resultado = date.getTime() <= actual.getTime();
    console.log(this.form.controls.scheduled_expiration_date.value);
    if (resultado) {
      this.actualdatevalidation();
    } else {
      this.setExpirationDate();
    }
  }
  changehoursice(hourLimit: any, isHourSince: boolean = true) {
    if (
      isHourSince &&
      moment(this.form.controls.scheduled_date_programming.value).format(
        "YYYY-MM-DD"
      ) == moment().format("YYYY-MM-DD")
    ) {
      if (!this.checkHourWithTimeZone(hourLimit)) {
        this.form.controls.scheduled_hour_since.setValue(null);
        this.expirationHour = undefined;

        return false;
      }
    }
    this.form.controls.scheduled_hour_limit.setValue(hourLimit);
    this.expirationHour = moment(
      this.form.controls.scheduled_hour_limit.value,
      "h:mm a"
    );
    if (!this.expirationHour.isValid()) {
      this.expirationHour = undefined;
    }
  }
  changetimelimit() {
    var horaInicio = moment(
      this.form.controls.scheduled_hour_since.value,
      "h:mma"
    );
    var horaFin = moment(
      this.form.controls.scheduled_hour_limit.value,
      "h:mma"
    );
    console.log(horaInicio.isBefore(horaFin)); // deber� aparecer true
    if (
      horaInicio.isBefore(horaFin) == false &&
      this.form.controls.scheduled_expiration_date.value == 0
    ) {
      this.form.controls.scheduled_hour_limit.setValue(null);
    }
    this.changehoursice(this.form.controls.scheduled_hour_limit.value, false);
  }
  checkHourWithTimeZone(hourSince) {
    let currentDateTimezone =
      this.timezoneConversionService.getTimeConvertedTimeZone(
        new Date(),
        this.currentTimeZone.reference
      );
    let currentHour = moment(currentDateTimezone.hora24, "h:mma");
    let setHour = moment(hourSince, "h:mma");
    return currentHour.isBefore(setHour);
  }
  sendSuccess(text: string) {
    this.modalGeneric
      .onModelexito(
        false, //disableClose
        text, //message
        false, // cancelButton
        this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
        true, // info
        false //imagen info
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.dialogRef.close("aceptar");
      });
  }
  sendModelError(msg: string) {
    this.spinner.hide();
    this.modalGeneric.onModelerror(
      false, //disableClose
      msg, //message
      false, // cancelButton
      this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
      true, // info
      false //imagen info
    );
  }
  public onCancelar() {
    this.dialogRef.close("cancelar");
  }

  validarinputvalue() {
    let input = this.form.controls.scheduled_expiration_date.value;
    this.form.controls.scheduled_expiration_date.setValue(
      input.replace(/\D/g, "")
    );
    if (
      this.form.controls.scheduled_hour_limit.value != null &&
      this.form.controls.scheduled_hour_limit.value != ""
    ) {
      this.changetimelimit();
    }
    this.setExpirationDate();
  }
  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }
  selectedTipos(event) {
    this.tipoSel = event;
  }

  detailEvent() {
    if (this.storageService.getCurrentDB() != "") {
      const urlActividades =
        this.authService.customerSelected.url_report +
        this.programming.firebase_id;
      window.open(urlActividades, "blank");
    }
  }

  deleteEvent() {
    const cust = this.programming;
    const scheduled_periodicity = this.programming.scheduled_periodicity;
    const serie = this.programming.serie;
    console.log(cust);
    var date = new Date(cust.scheduled_programming_date._seconds * 1000);

    let fecha = this.fechainarray(date, 2);
    console.log(fecha);
    this.modalGeneric
      .onModelconfirmacionEvent(
        scheduled_periodicity,
        false, //disableClose
        "¿Está seguro de eliminar esta actividad?", //message
        true, // cancelButton
        this.translateService.instant(
          "PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE"
        ), //confimButtonText
        true, // info
        false //imagen info
        //title
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp.button === "aceptar") {
          this.spinner.show();
          this.cd.detectChanges();
          let ide = cust.firebase_id;
          let tipo = 2;
          if (resp.radiodelete == "all") {
            ide = serie;
            tipo = 1;
          }
          let params = { type: tipo, id: ide, fecha: fecha };
          console.log(params);
          this.programingservice.deleteProgrammingEvent(params).subscribe(
            (response) => {
              console.log("response", response);
              if (response.status == "ok") {
                this.spinner.hide();
                this.cd.detectChanges();
                this.sendSuccess("La actividad se ha eliminado correctamente");
              }
            },
            (error) => {
              console.log("error", error);
            }
          );
        }
      });
  }
}
